<template>
  <el-dialog
    title="移动知识点"
    class="yt-dialog yt-dialog-default"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-input
      v-model="knowledgePointDownKeyword"
      class="yt-search search"
      placeholder="通过知识点名称筛选"
      @keydown.enter.native="toFilterNode('knowledge-tree-down')"
    >
      <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterNode('knowledge-tree-down', knowledgePointDownKeyword)"></i>
    </el-input>
    <el-tree
      :data="knowledgePointsDown"
      :props="knowledgePointProps"
      @node-click="knowledgeMove"
      node-key="value"
      ref="knowledge-tree-down"
      :filter-node-method="filterNode"
      style="margin-left: 90px"
    >
    </el-tree>
    <div class="footer" slot="footer">
      <el-button @click="moveCancel" class="button">取消</el-button>
      <el-button type="primary" :loading="loading" class="button" @click="moveKnowledge">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import knowledge from '@api/knowledge'
import questionApi from '@api/question'
export default {
  name: '',
  data() {
    return {
      knowledgePointsDown: [],
      knowledgePointDownKeyword: '',
      visible: false,
      knowledgePoint: [],
      loading: false,
      knowledgePointProps: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      postForm: {
        parentId: ''
      },
      moveToKnowledge: ''
    }
  },
  methods: {
    knowledgeMove(data) {
      this.moveToKnowledge = data.value
    },
    moveKnowledge() {
      if (this.moveToKnowledge === '') {
        this.$message.error('请选择移动到的知识点')
      } else {
        this.loading = true
        const payload = {
          parentId: this.moveToKnowledge,
          knowledgeId: this.postForm.parentId
        }
        knowledge
          .moveKnowledge(payload)
          .then(res => {
            if (res.res === true) {
              this.$message.success('知识点移动成功')
              this.$emit('refresh')
              this.getKnowledgeTree()
              this.visible = false
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    toFilterNode(ref) {
      this.$refs[ref].filter(this.knowledgePointDownKeyword)
      this.$refs[ref].setCheckedNodes([])
    },
    getKnowledgeTree() {
      //获取知识点树
      questionApi.getKnownLedgeTree().then(res => {
        this.knowledgePointsDown = res.res
      })
    },
    open(parentId) {
      this.visible = true
      this.postForm.parentId = parentId
      this.getKnowledgeTree()
    },
    close(done) {
      done()
    },
    closed() {
      this.moveToKnowledge = ''
      this.loading = false
    },
    moveCancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.search {
  width: 350px;
  height: 38px;
  margin-bottom: 10px;
  .yt-search .el-input__icon {
    margin-right: 10px;
  }
  ::v-deep .el-input__icon {
    line-height: 38px;
  }
}
::v-deep .el-tree__empty-block {
  right: 45px;
}
</style>
